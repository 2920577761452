import React from "react";

const MapSection = () => {
  return (
    <div className="map">
      <iframe
        className="w-100 h-100 border-0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2656.7772152469424!2d1.2729366999999998!3d48.249415899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e388b4da06afc5%3A0x2db44b05dd9aac09!2s3%20Rue%20Eug%C3%A8ne%20Foussard%2C%2028160%20Dangeau!5e0!3m2!1sfr!2sfr!4v1687350363237!5m2!1sfr!2sfr"
        allowFullScreen=""
        title="Google Maps"
      ></iframe>
    </div>
  );
};

export default MapSection;
