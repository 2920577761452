import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import PackageCard from "./PackageCard";
import LottiePlayer from "../player";
function Pricing() {
  const packages = [
    {
      title: "Basique",
      subTitle: "Vitrine onepage pour la présence digitale",
      price: 699,
      features: [
        "Design standard (template)",
        "Onepage landing",
        "SEO Standard",
      ],
      buttonColor: "dark",
      mo: false
    },
    {
      title: "Premium",
      subTitle: "Simple site vitrine avec notre solution Aonyx.io",
      price: 1499,
      features: [
        "Design premium",
        "10 Pages responsive",
        "Aonyx-Dashboard API Integration",
        "1 e-mail Pro",
        "SEO Optimisée",
        "1 an de support garantis",
      ],
      buttonColor: "light",
      mo: false
    },
    {
      title: "Ultime",
      subTitle: "Tout-en-un avec notre solution Aonyx.io (Site de type Vitrine, portfolio, e-commerce, CRM...)",
      price: 399,
      features: [
        "Design premium",
        "Pages responsives illimitées",
        "Aonyx-Dashboard API Integration",
        "50% de remise sur les nouvelles features",
        "E-mail pro illimitées",
        "SEO Optimisée",
        "Inclus Hébergement & Nom de domaine",
        "Lifetime support",
      ],
      buttonColor: "dark",
    },
  ];

  return (
    <div>
      <section className="position-relative overflow-hidden">
        <Container>
          <Row className="justify-content-center text-center mb-6">
            <Col className="col-12 col-lg-8">
              <h2>Nos tarifs</h2>
              <p className="lead">
                Choisissez le plan qui vous correspond le mieux
              </p>
            </Col>
          </Row>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row align-items-center">
                {packages.map((pkg, index) => (
                  <div key={index} className="col-12 col-lg-4 mb-8 mb-lg-0">
                    <PackageCard {...pkg} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
        <div className="position-absolute animation-1">
          <LottiePlayer src="https://lottie.host/b3b9dd01-8da3-408d-bf97-301eb5efd93c/fmsV6COvHR.json" />
        </div>
      </section>
    </div>
  );
}

export default Pricing;
