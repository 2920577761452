import { useEffect, useState } from 'react';
import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import LottiePlayer from "../player";
import Bg from "../../public/js/lottie/heroBanner1.json";

function HeroBanner1() {

  return (
    <div>
      <section className="overflow-hidden position-relative">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="order-lg-1 ms-auto mb-lg-0">
              <LottiePlayer src="https://lottie.host/3ddc0e30-a9f7-48e1-9b8d-a0ead2fa5ee4/JrQpGMHV6n.json" />
            </Col>
            <Col xs={12} lg={5}>
              <h1 className="font-w-4 mb-4">
                Créer un
                <span className="title-bg text-primary position-relative font-w-7 d-inline-block">
                  Site Internet
                </span>
                <br className="d-md-block d-none" />
                <div className="content mt-5">
                  <div className="content__container">
                    <ul className="content__container__list">
                      <li className="content__container__list__item">à Chartres (28)</li>
                      <li className="content__container__list__item">à Châteaudun (28)</li>
                      <li className="content__container__list__item">à Nogent-le-Rotrou (28)</li>
                      <li className="content__container__list__item">à Bonneval (28)</li>
                    </ul>
                  </div>
                </div>
              </h1>
              <p className="lead text-dark mt-11 mb-5">
                Donnez vie à votre site web avec l'aide de nos experts talentueux.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="position-absolute animation-1">
          <LottiePlayer src={Bg} />
        </div>
      </section>
    </div>
  );
}

export default HeroBanner1;
