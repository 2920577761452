import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import { Col, Container, Row } from "reactstrap";
import Contact from "../../Components/contact/Contact";

function privacy() {
  const firstBreadcrumb = { label: "Pages", link: "/privacy-policy" };
  const secondBreadcrumb = {
    label: "Privacy & Policy",
    link: "/privacy-policy",
    active: true,
  };
  const data = [
    {
      text: "Lorem ipsum dolor sit amet, consectetur",
    },
    {
      text: "Quidem error quae illo excepturi nostrum blanditiis laboriosam",
    },
    {
      text: "Molestias, eum nihil expedita dolorum odio dolorem",
    },
    {
      text: "Eum nihil expedita dolorum odio dolorem",
    },
    {
      text: "Explicabo rem illum magni perferendis",
    },
  ];
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Politique de confidentialité du site"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section>
          <Container>
            <Row>
              <Col lg="12" md="12">
                <p>
                  La présente politique de confidentialité explique comment nous recueillons, utilisons, divulguons et protégeons vos informations personnelles lorsque vous visitez notre site. Votre vie privée est importante pour nous, et nous nous engageons à respecter et à protéger vos droits en matière de confidentialité.
                </p>
                <h4 className="mt-5 text-primary">Collecte des informations</h4>
                <p>
                  Lorsque vous visitez notre site, nous pouvons recueillir des informations personnelles vous concernant de différentes manières, y compris lorsque vous remplissez des formulaires, vous vous abonnez à notre newsletter, effectuez des achats ou interagissez avec nos services. Les informations que nous pouvons collecter peuvent inclure votre nom, votre adresse e-mail, votre adresse postale, votre numéro de téléphone, des informations de paiement, des données démographiques et d'autres informations pertinentes.
                </p>
                <h4 className="mt-5 text-primary">Utilisation des informations</h4>
                <p>
                  Nous utilisons les informations personnelles collectées pour fournir, maintenir et améliorer nos services, répondre à vos demandes, personnaliser votre expérience utilisateur, effectuer des transactions, vous envoyer des informations promotionnelles, des bulletins d'information ou d'autres communications marketing, détecter et prévenir les fraudes, assurer la sécurité de notre site, et se conformer aux lois et réglementations applicables.
                </p>
                <h4 className="mt-5 text-primary">Divulgation des informations</h4>
                <p>
                  Nous ne vendons pas, ne louons pas et ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
                  <ul>
                    <li>Lorsque vous nous avez donné votre consentement pour partager vos informations.</li>
                    <li>Lorsque la divulgation est nécessaire pour exécuter un contrat ou une transaction avec vous.</li>
                    <li>Lorsque nous sommes tenus de le faire par la loi, une procédure judiciaire ou une demande gouvernementale.</li>
                    <li>Lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou la sécurité d'autrui, enquêter sur une fraude ou répondre à une situation d'urgence.</li>
                    <li>Lorsque nous faisons appel à des prestataires de services tiers pour nous aider à exploiter notre site ou à fournir nos services, sous réserve des obligations de confidentialité appropriées.</li>
                  </ul>
                </p>
                <h4 className="mt-5 text-primary">Sécurité des informations</h4>
                <p>
                  Nous prenons les mesures de sécurité appropriées pour protéger vos informations personnelles contre toute perte, utilisation abusive, accès non autorisé, divulgation, altération ou destruction. Cependant, veuillez noter qu'aucune méthode de transmission ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.
                </p>
                <h4 className="mt-5 text-primary">Cookies et technologies similaires</h4>
                <p>
                  Nous utilisons des cookies et d'autres technologies similaires pour collecter des informations sur votre utilisation de notre site. Ces technologies nous aident à améliorer votre expérience utilisateur, à analyser les tendances, à administrer le site, à suivre les mouvements des utilisateurs sur le site et à recueillir des informations démographiques sur notre base d'utilisateurs dans son ensemble. Vous pouvez contrôler l'utilisation des cookies dans les paramètres de votre navigateur.
                </p>
                <h4 className="mt-5 text-primary">Liens vers des sites tiers</h4>
                <p>
                  Notre site peut contenir des liens vers des sites web de tiers. Cette politique de confidentialité ne s'applique pas aux pratiques de confidentialité de ces sites. Nous vous encourageons à consulter les politiques de confidentialité de ces sites avant de fournir vos informations personnelles.
                </p>
                <h4 className="mt-5 text-primary">Modifications de la politique de confidentialité</h4>
                <p>
                  Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour. Votre utilisation continue de notre site après la publication des modifications constitue votre acceptation de ces modifications.
                </p>
                <h4 className="mt-5 text-primary">Nous contacter</h4>
                <p>
                  Si vous avez des questions, des préoccupations ou des demandes concernant cette politique de confidentialité ou nos pratiques en matière de confidentialité, veuillez nous contacter à <strong>contact@galicher.fr</strong>.
                  <br /><br />
                  Cette politique de confidentialité est effective à partir de la date de mise à jour indiquée ci-dessous.
                  <br /><br />
                  Date de mise à jour : <strong>21/06/2023</strong>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <Contact />
      </div>
    </div>
  );
}

export default privacy;
