import React from "react";
import { Container, Row, Col } from "reactstrap";
import TeamMembers from "./TeamMemberCard";
function Team() {
  const teamMembers = [
    {
      id: 1,
      name: "Damien GALICHER",
      position: "Développeur et CEO",
      imgSrc: "images/team/1583310973192.png",
      emaiId: "contact@galicher.fr",
      phoneNo: "+33 7 68 76 65 70",
      socialMedia: [
        { icon: "bi-facebook", link: "/index-2" },
        { icon: "bi-twitter", link: "/index-2" },
        { icon: "bi-linkedin", link: "/index-2" },
      ],
    },

  ];
  return (
    <div>
      <section>
        <Container>
          <div className="row justify-content-center text-center mb-6">
            <Col xs="12" lg="8">
              <div>
                <h6 className="border-bottom border-dark border-2 d-inline-block">
                  Equipe
                </h6>
                <h2 className="font-w-6">Qui sommes nous ?</h2>
                <p className="lead mb-0">
                  Nous sommes une équipe de développeurs expérimentés et passionnés
                  par leur travail
                </p>
              </div>
            </Col>
          </div>
          <Row>
            <TeamMembers teamMembers={teamMembers} />
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Team;
