import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardComponent from "./FeatureCard";
import LottiePlayer from "../player";
import Bg from "../../public/js/lottie/feature.json";

function Feature() {
  const cardData = [
    {
      title: "Développer les affaires",
      description:
        "Tirez le meilleur parti de votre présence en ligne, avoir un site c'est offrir à votre entreprise une vitrine en ligne attrayante et accessible 24 heures sur 24, 7 jours sur 7, permettant d'attirer, d'engager et de fidéliser vos clients, tout en renforçant votre crédibilité et en vous démarquant de la concurrence.",
      backgroundColor: "#ffebda",
      iconClass: "bi bi-graph-up-arrow",
    },
    {
      title: "Personnaliser facilement",
      description:
        "Votre site vitrine, composé d'une série de pages internet, est conçu de manière modulaire, ce qui facilite les modifications et l'ajout de contenu, vous permettant ainsi, même en tant que débutant, de personnaliser et d'enrichir votre site selon vos besoins.",
      backgroundColor: "#dbf9f9",
      iconClass: "bi bi-gear",
    },
    {
      title: "Nouveaux prospects",
      description:
          "Votre site vitrine joue le rôle de votre carte de visite en ligne, étant la première impression que les prospects ont de vous. Considérez-le comme un investissement visant à attirer un grand nombre de prospects et à augmenter votre chiffre d'affaires, il ne faut pas le voir uniquement comme une dépense. ",
      backgroundColor: "#faedff",
      iconClass: "bi bi-hand-thumbs-up-fill",
    },
  ];

  return (
    <div>
      <section className="px-lg-7 px-2 pb-0 pt-0">
        <div className="bg-light py-10 px-3 px-lg-8 rounded-4 position-relative overflow-hidden">
          <Container className="z-index-1">
            <Row className="align-items-end justify-content-between mb-6">
              <Col xs={12} lg={6} xl={5}>
                <div>
                  <h2>
                    Votre agence de développement web</h2>
                </div>
              </Col>
              <Col xs={12} lg={6} xl={5} className="ms-auto mt-5 mt-lg-0">
                <p className="lead">
                  Nous sommes une équipe de développeurs expérimentés et passionnés
                  par leur travail.<br /> Nous nous engageons.
                </p>
              </Col>
            </Row>
            <Row className="gx-5">
              {cardData.map((data, index) => (
                <Col
                  style={{
                    marginTop: "2.5rem",
                    "@media screen and (minWidth: 700px)": { marginTop: 0 },
                  }}
                  lg={4}
                  md={6}
                  key={index}
                >
                  <CardComponent
                    title={data.title}
                    description={data.description}
                    backgroundColor={data.backgroundColor}
                    iconClass={data.iconClass}
                  />
                </Col>
              ))}
            </Row>
          </Container>
          <div className="position-absolute animation-2">
            <LottiePlayer src={Bg} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Feature;
