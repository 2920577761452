export default [
  {
    category: "Vitrines",
    imgSrc: "images/portfolio/maisonferre.jpg",
    title: "La Maison Ferré",
    to: "https://www.lamaisonferre.fr/"
  },
  {
    category: "CRM",
    imgSrc: "images/portfolio/aonyx.jpg",
    title: "Aonyx Dashboard",
    to: "https://www.aonyx.dev/"
  }
];