const blogList = [
  {
    image: "images/blog/01.jpg",
    date: "20 juin 2023",
    category: "Business",
    title: "Lancement de l'agence web Galicher Solutions",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae sed. Potenti aliquam feugiat proin facilisi. ac nunc always made uniquely calibrated.",
  },

];

export default blogList;
