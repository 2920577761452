import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import { Container, Row, Col, Button } from "reactstrap";
import Contact from "../../Components/contact/Contact";

function tAndC() {
  const firstBreadcrumb = { label: "Pages", link: "/terms-and-conditions" };
  const secondBreadcrumb = {
    label: "Termes et conditions d'utilisation",
    link: "/terms-and-conditions",
    active: true,
  };
  const data = [
    {
      title: " ",
      content:
        "Veuillez lire attentivement les présents termes et conditions avant d'utiliser notre site internet. En accédant à ce site ou en l'utilisant de quelque manière que ce soit, vous acceptez d'être lié par ces termes et conditions. Si vous n'acceptez pas ces termes, veuillez ne pas utiliser notre site.",
    },
    {
      title: "Utilisation du site",
      items: [
        "a. Vous êtes autorisé à accéder à notre site internet et à l'utiliser uniquement à des fins légales et conformément aux présents termes et conditions.\n",
        "b. Vous êtes responsable de vous assurer que votre utilisation du site ne viole aucune loi applicable, réglementation ou droit de tiers.\n",
        "c. Vous ne devez pas utiliser le site d'une manière qui pourrait endommager, désactiver, surcharger ou compromettre le fonctionnement du site ou nuire à d'autres utilisateurs.",
      ],
    },
    {
      title: "Contenu du site",
      items: [
        "a. Le contenu de ce site est fourni à titre informatif uniquement. Nous ne garantissons pas l'exactitude, l'exhaustivité ou la pertinence du contenu.\n",
        "b. Nous nous réservons le droit de modifier, mettre à jour ou supprimer tout contenu du site à tout moment, sans préavis.",
      ]
    },
    {
      title: "Propriété intellectuelle",
      items: [
        "a. Tout le contenu présent sur ce site, y compris, mais sans s'y limiter, le texte, les graphiques, les logos, les images, les vidéos, les clips audio, les marques de commerce, les noms de domaine et les logiciels, est la propriété exclusive de notre entreprise ou de nos concédants de licence, et est protégé par les lois sur la propriété intellectuelle en vigueur.",
        "b. Vous n'êtes pas autorisé à reproduire, distribuer, modifier, afficher publiquement, transmettre ou exploiter de quelque manière que ce soit le contenu du site sans notre consentement écrit préalable.",
      ],
    },
    {
      title: "Liens vers des sites tiers",
      content:
        "Notre site peut contenir des liens vers des sites web tiers. Ces liens sont fournis uniquement pour votre commodité. Nous n'avons aucun contrôle sur le contenu de ces sites tiers et n'assumons aucune responsabilité quant à leur exactitude, leur légalité ou leur fonctionnement. L'inclusion de tout lien vers un site tiers n'implique pas notre approbation ou notre affiliation avec ce site.",
    },
    {
      title: "Limitation de responsabilité",
      items: [
        'a. Dans toute la mesure permise par la loi applicable, nous déclinons toute responsabilité pour les dommages directs, indirects, accessoires, consécutifs ou spéciaux résultant de l\'utilisation ou de l\'impossibilité d\'utiliser notre site, y compris les pertes de profits, de données ou de réputation, même si nous avons été informés de la possibilité de tels dommages.',
        'b. Nous ne garantissons pas que notre site sera exempt d\'erreurs, de virus ou d\'autres composants nuisibles, ni que son fonctionnement sera ininterrompu ou sécurisé.'
      ],
    },
    {
      title: "Modification des termes et conditions",
      content: "Nous nous réservons le droit de modifier ou de mettre à jour ces termes et conditions à tout moment, sans préavis. Il est de votre responsabilité de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.",
    },
    {
      title: "Droit applicable et juridiction compétente",
      content: "Ces termes et conditions sont régis par les lois en vigueur dans le pays où notre entreprise est établie. Tout litige découlant de l'utilisation de notre site sera soumis à la juridiction exclusive des tribunaux compétents de cette juridiction.",
    },
  ];
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Termes et conditions d'utilisation"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <section>
        <Container>
          <Row>
            <Col lg="12" md="12">
              {data.map((section, index) => (
                <div key={index}>
                  <h4 className="text-dark mt-5">{section.title}</h4>
                  <p className="mb-3">{section.content}</p>
                  {section.items && section.items.length > 0 && (
                    <React.Fragment>
                      {section.items.map((item, itemIndex) => (
                        <div
                          className="d-flex align-items-center mb-3"
                          key={itemIndex}
                        >
                          <div className="bg-light rounded px-2 py-1">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-check"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </div>
                          <p className="mb-0 ms-3">{item}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </div>
              ))}

            </Col>
          </Row>
        </Container>
      </section>
      <Contact />
      );
    </div>
  );
}

export default tAndC;
