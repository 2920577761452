import React from "react";
import { Container, Row, Col } from "reactstrap";
import LottiePlayer from "../player";
import Bg from "../../public/js/lottie/about1.json";

function About() {
  const benefits = [
    {
      icon: "bi-check2-all",
      title: "Rendez-vous",
      description:
        "Contactez-nous pour prendre un rendez-vous, celui-ci dure en moyenne 30 à 60 minutes pour discuter de votre projet et trouver la solution la plus adaptée à vos besoins.",
    },
    {
      icon: "bi-check2-all",
      title: "Devis",
      description:
        "Nous vous présentons un devis adapté à votre demande et à vos besoins. Le devis ne vous satisfait pas ? Aucun soucis, nous le retravaillerons autant de fois que nécessaire.",
    },
    {
      icon: "bi-check2-all",
      title: "Développement",
      description:
        "Nous concevons le site de sorte à ce qu’il soit de qualité et bien référencé sur les moteurs de recherche comme Google, élément clé du succès de votre projet.",
    },
    {
      icon: "bi-check2-all",
      title: "Livraison",
      description:
        "Toute nos félicitations, votre site vitrine est disponible ! Il va maintenant falloir en prendre soin ! Nous vous formons à son utilisation et à son actualisation.",
    },
  ];
  return (
    <div>
      <section >
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-8 mb-lg-0 order-lg-1">
              <LottiePlayer src={Bg} />
            </Col>
            <Col xs={12} lg={6}>
              <div>
                <h2 className="mb-5">Les étapes de création de votre site</h2>
              </div>
              {benefits.map((benefit, index) => (
                <div className="d-flex align-items-start mb-4" key={index}>
                  <div className="me-3">
                    <i className={`bi ${benefit.icon} fs-3 text-primary`}></i>
                  </div>
                  <div>
                    <h5 className="mb-2">{benefit.title}</h5>
                    <p className="mb-0">{benefit.description}</p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default About;
