import logo from "./logo.svg";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./Components/header/Header";
import Index from "./Pages/index";
import Footer from "./Components/footer/Footer";
import Team from "./Pages/team-page/TeamPage";
import FaqPage from "./Pages/faq-page/FaqPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PortFolioCard from "./Pages/portfolio/PortFolioCard";
import BackToTop from "./Components/BackToTop";
import Contact from "./Pages/contact/Contatct";
import ErrorPage from "./Pages/error-page/ErrorPage";
import TAndC from "./Pages/term-And-Condition/tAndC";
import Privacy from "./Pages/term-And-Condition/privacy";
import { useEffect, useState } from "react";
function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 50); // Change the delay as needed

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const isSpecialRoute =
    location.pathname.includes("maintenance") ||
    location.pathname.includes("coming-soon") ||
    location.pathname.includes("error-404");
  // console.log(isSpecialRoute);

  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload(); // Refresh the page on popstate event (back button)
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading ? (
        <div id="ht-preloader">
          <div className="loader clear-loader">
            <img src="images/loader.gif" alt="" />
          </div>
        </div>
      ) : (
        // Rest of your JSX code
        <>
          {isSpecialRoute ? (
            <Routes>
              <Route path="/error-404" element={<ErrorPage />} />
            </Routes>
          ) : (
            <div className="page-wrapper">
              <Header />
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/team" element={<Team />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/portfolio-card" element={<PortFolioCard />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/terms-and-conditions" element={<TAndC />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
              <Footer />
              <BackToTop />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default App;
