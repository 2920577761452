import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import Accordion from "../../Components/faq/Accordian";
import { Col, Container, Row, Card } from "reactstrap";
import Contact from "../../Components/contact/Contact";

function FaqPage() {
  const firstBreadcrumb = { label: "Pages", link: "/faq" };
  const secondBreadcrumb = {
    label: "F.A.Q",
    link: "/faq",
    active: true,
  };
  const accordionItems = [
    {
      id: 1,
      title: "Quels services proposez-vous pour la création d'un site internet ?",
      content:
        "En tant qu'agence de développement de sites internet, nous offrons une gamme complète de services, y compris la conception web, le développement personnalisé, l'intégration de fonctionnalités spécifiques, l'optimisation pour les moteurs de recherche (SEO) et l'assistance continue.",
    },
    {
      id: 2,
      title: "Comment puis-je choisir un nom de domaine adapté à mon entreprise ?",
      content:
        "Nous vous aidons à choisir un nom de domaine qui reflète votre marque et votre activité. Nous effectuons des recherches approfondies pour trouver des options disponibles et conseillons sur les meilleures pratiques en matière de choix de nom de domaine.",
    },
    {
      id: 3,
      title: "Qu'est-ce que la conception web personnalisée ?",
      content:
        "Notre équipe de concepteurs web expérimentés crée des designs sur mesure en fonction de vos besoins et de vos objectifs spécifiques. Nous travaillons en étroite collaboration avec vous pour donner vie à votre vision et fournir une expérience utilisateur optimale.",
    },
    {
      id: 4,
      title: "Proposez-vous des fonctionnalités spécifiques pour les sites internet ?",
      content:
        "Absolument ! Nous sommes en mesure d'intégrer des fonctionnalités sur mesure à votre site internet, telles que des formulaires de contact avancés, des boutiques en ligne, des systèmes de réservation, des galeries d'images à l'aide de notre système gestion de contenu (CMS) et/ou d'autres outils déjà existant",
    },
    {
      id: 5,
      title: "Comment assurez-vous la visibilité de mon site internet sur les moteurs de recherche ?",
      content:
        "Nous mettons en œuvre des techniques de référencement naturel (SEO) pour améliorer le classement de votre site dans les résultats de recherche. Cela comprend l'optimisation des mots-clés, la création de contenu pertinent, l'amélioration de la structure du site et la génération de liens de qualité.",
    },
    {
      id: 6,
      title: "Fournissez-vous des services d'hébergement web ?",
      content:
        "Nous pouvons vous aider à sélectionner un hébergeur web fiable et adapté à vos besoins. Nous veillons à ce que votre site soit hébergé sur des serveurs performants, sécurisés et évolutifs.",
    },
    {
      id: 7,
      title: "Offrez-vous des services de maintenance et de support après la création du site ?",
      content:
        "Oui, nous proposons des contrats de maintenance pour garantir le bon fonctionnement de votre site à long terme. Nous effectuons des mises à jour régulières, assurons des sauvegardes régulières, et fournissons un support technique en cas de besoin.",
    },
  ];
  return (
    <div>
      <Page_Heading
        title="Questions fréquemment posées"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section className="pb-10">
          <Container>
            <Row>
              <Col>
                <div className="bg-light-2 p-lg-8 p-4 rounded-4">
                  <Accordion accordionItems={accordionItems} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Contact />
      </div>
    </div>
  );
}

export default FaqPage;
