import React, { useState } from "react";
import { Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Envoi du formulaire
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('topic', topic);
    formData.append('message', message);

    fetch('https://api.aonyx.dev/contact', {
      mode: 'no-cors',
      method: 'POST',
      body: formData,
    })
        .then(response => {

          // Le mail a été envoyé avec succès
          alert('Le message a été envoyé avec succès !');

        })
        .catch(error => {
          // Erreur lors de l'envoi de la requête
          console.log('Erreur lors de l\'envoi de la requête :', error);
          alert('Une erreur s\'est produite lors de l\'envoi du message. Veuillez réessayer plus tard.');
        });
  }

  return (
    <div className="bg-white shadow p-5 rounded-4">
      <Form id="contact-form" className="row" onSubmit={handleSubmit}>
        <div className="messages"></div>
        <FormGroup className="col-md-6">
          <Label className="font-w-6">Prénom</Label>
          <Input
            type="text"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Prénom"
            required
            data-error="Name is required."
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-6">
          <Label className="font-w-6">Nom</Label>
          <Input
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Nom"
            required
            data-error="Name is required."
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">E-mail</Label>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
            required
            data-error="Valid email is required."
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Téléphone</Label>
          <Input
            type="tel"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Téléphone"
            required
            data-error="Phone is required"
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Formule</Label>
          <Input
            type="select"
            name="formule"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          >
            <option value="">- Choisir une formule</option>
            <option value="basique">Basique</option>
            <option value="premium">Premium</option>
            <option value="ultime">Ultime</option>
          </Input>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Message</Label>
          <Input
            type="textarea"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="form-control rounded-4 h-auto"
            placeholder="Message"
            rows="4"
            required
            data-error="Please leave us a message."
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <Col className="mt-4">
          <Button color="primary" type="submit">
            Transmettre ma demande
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default ContactForm;
