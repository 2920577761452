import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import LottiePlayer from "../player";

function Footer() {
  const navigate = useNavigate();
  const socialMedia = [
    { icon: "bi-facebook", link: "https://www.facebook.com/galicher.solutions" },
    { icon: "bi-instagram", link: "https://www.instagram.com/galicher.solutions/" },
    { icon: "bi-twitter", link: "https://twitter.com/GalicherFr" },
    { icon: "bi-linkedin", link: "https://www.linkedin.com/in/damien-galicher-4544701a3/" },
  ];
  return (
    <div>
      <footer className="py-11 position-relative overflow-hidden">
        <Container className="z-index-1">
          <Row>
            <Col md={12} lg={4} className="pe-lg-7">
              <Link className="footer-logo" to="/">
                <img className="img-fluid" src="images/logo-slog.png" alt="" />
              </Link>
              <ul className="media-icon list-unstyled">
                <ListGroupItem className="mb-2">
                  <Link to="mailto:contact@galicher.fr" className="h6">
                    contact@galicher.fr
                  </Link>
                </ListGroupItem>
                <ListGroupItem>
                  <Link to="tel:+33768766570" className="h6">
                    +33 7 68 76 65 70
                  </Link>
                </ListGroupItem>
              </ul>
            </Col>

            <Col md={6} lg={2} className="mt-6 mt-lg-0 ">
              <h5 className="mb-4">Liens</h5>
              <Nav className="navbar-nav list-unstyled mb-0">
                <NavItem className="mb-3">
                  <Link to="/team" className="nav-link">
                    Equipe
                  </Link>
                </NavItem>
                <NavItem className="mb-3">
                  <Link to="/contact-us" className="nav-link">
                    Contactez-nous
                  </Link>
                </NavItem>
              </Nav>
            </Col>

            <Col md={6} lg={2} className="mt-6 mt-lg-0 footer-menu">
              <h5 className="mb-4">Mentions</h5>
              <Nav className="navbar-nav list-unstyled mb-0">
                <NavItem className="mb-3">
                  <Link to="/faq" className="nav-link">
                    F.A.Q
                  </Link>
                </NavItem>
                <NavItem className="mb-3">
                  <Link to="/terms-and-conditions" className="nav-link">
                    Termes et services
                  </Link>
                </NavItem>
                <NavItem className="mb-3">
                  <Link to="/privacy-policy" className="nav-link">
                    Politique de confidentialité
                  </Link>
                </NavItem>
              </Nav>
            </Col>
            <Col md={8} lg={4} className="mt-6 mt-lg-0">
              <h5 className="mb-4">Adresse</h5>
              <div className="mb-3 text-muted" style={{ fontSize: "16px" }}>
                <i className="text-primary fs-4 align-middle bi bi-geo-alt me-2"></i> 3 rue Eugène Foussard 28160 Mézières au perche{" "}
              </div>

              <ul horizontal className="list-inline  mb-3">
                {socialMedia.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    className="list-inline-item"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <Link to={item.link} className="text-dark fs-3">
                      <item.icon />
                      <i className={`bi ${item.icon}`}></i>
                    </Link>
                  </ListGroupItem>
                ))}
              </ul>
            </Col>
          </Row>
          <Row className="mt-7">
            <Col className="text-center text-dark">
              Copyright &copy; 2023 &nbsp;
              <u>
                <a href="#">Galicher Solutions </a>
              </u>
            </Col>
          </Row>
        </Container>
        <div className="position-absolute animation-1 opacity-1">
          <LottiePlayer src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json" />
        </div>
      </footer>
    </div>
  );
}

export default Footer;
